<template>
  <div class="wrap">
    <PullRefresh
      success-text="刷新成功"
      v-model="loading"
      @refresh="onRefresh"
      class="refresh"
    >
      <div class="box">
        <div class="topWrap">{{ name || '未知' }}</div>
        <Button type="primary" size="large" @click="show5=true">
          <div class="iconWrap"><i class="iconfont icon-jiguihang" /></div>
          <div>清柜[打开所有柜门并清空数据]</div>
        </Button>
        <div class="botWrap">
          <div class="titleWrap2">
            <span class="xz" />
            <span>闲置</span>
            <span class="syz" />
            <span>使用中</span>
            <!-- <span>异常</span>
            <span class="yc" /> -->
            <span class="ty" />
            <span>停用</span>
            <span class="sh" />
            <span>损坏</span>
            <span class="fc" />
            <span>封存</span>
          </div>
          <div class="cardWrap">
            <div>
              <div
                v-for="(value, index) in list"
                :key="index" class="itemWrap"
                :style="{background: colorMap[value.status]}"
                @click="showModal1(value, index)"
              >
                {{ value.alias + '-' + ('0' + value.boxNo).substring(('0' + value.boxNo).length - 2) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PullRefresh>
    <Overlay :show="show" class="overlay">
      <Loading type="spinner" />
    </Overlay>
    <Dialog
      v-model="show1"
      showCancelButton
      :showConfirmButton="false"
      @cancel="show1=false;orderData={};currentNo=''"
      class="modal"
    >
      <div class="currentNo">{{ currentNo }}号柜子</div>
      <div class="warn">注意：
        <span v-if="current.status === 1">当前显示最新一单记录（闲置中）</span>
        <span v-else-if="current.status === 2">当前显示最新一单记录（使用中）</span>
        <span v-else-if="current.status === 3">当前显示最新一单记录（已停用）</span>
        <span v-else-if="current.status === 4">当前显示最新一单记录（已损坏）</span>
      </div>
      <div class="contentWrap">
        <div>
          <span class="textLeft">订单状态：</span>
          <span class="textRight">{{ orderData.orderStatusName }}</span>
        </div>
        <div>
          <span class="textLeft">存包时间：</span>
          <span class="textRight">{{ orderData.startTime }}</span>
        </div>
        <div>
          <span class="textLeft">结束时间：</span>
          <span class="textRight">{{ orderData.endTime || '--' }}</span>
        </div>
        <div>
          <span class="textLeft">使用时长：</span>
          <span class="textRight">{{ orderData.strHours }}</span>
        </div>
        <div>
          <span class="textLeft">存包账号：</span>
          <span class="textRight num3">{{ orderData.phoneNumber }}</span>
        </div>
        <div>
          <span class="textLeft">存包密码：</span>
          <span class="textRight num3">{{ orderData.lockerPassword }}</span>
        </div>
      </div>
      <div class="btnWrap">
        <div>
          <Button plain type="primary" size="large" @click="showModal6">正常</Button>
        </div>
        <div>
          <Button plain type="primary" size="large" @click="showModal4">停用</Button>
        </div>
        <div>
          <Button plain type="primary" size="large" @click="showModal5">损坏</Button>
        </div>
      </div>
      <Button type="primary" size="large" style="margin-bottom: 10px" @click="showModal3">开柜</Button>
    </Dialog>
    <Dialog
      v-model="show2"
      title="修改柜门状态"
      showCancelButton
      :showConfirmButton="false"
      class="modal"
    >
      <Button type="primary" size="large" style="margin-bottom: 10px; border: 0" @click="changeStatus(1)">正常</Button>
      <Button type="primary" size="large" style="margin-bottom: 10px; border: 0; background: #e59760" @click="changeStatus(3)">异常</Button>
      <Button type="primary" size="large" style="margin-bottom: 10px; background: #e7263c; border: 0" @click="changeStatus(4)">停用</Button>
      <Button type="primary" size="large" style="background: #000; border: 0" @click="changeStatus(5)">损坏</Button>
    </Dialog>
    <Dialog
      v-model="show3"
      title="是否清除数据？"
      showCancelButton
      :showConfirmButton="false"
      class="modal"
    >
      <!-- <Button type="primary" size="large" style="margin-bottom: 10px" @click="openBox(1)">是</Button> -->
      <Button type="primary" size="large" style="margin: 10px 0" @click="openBox(0)">否</Button>
      <!-- <Button type="primary" size="large" style="background: #e7263c; border: 0" @click="openBox(0)">否</Button> -->
    </Dialog>
    <Dialog
      v-model="show4"
      title="是否打开所有柜门？"
      showCancelButton
      :showConfirmButton="false"
      class="modal"
    >
      <Button type="primary" size="large" style="margin-bottom: 10px" @click="openAllBox">是</Button>
      <Button type="primary" size="large" style="background: #e7263c; border: 0" @click="show4=false">否</Button>
    </Dialog>
    <Dialog
      v-model="show5"
      title="请输入密码验证"
      showCancelButton
      showConfirmButton
      @confirm="onpaymentyes"
    >
      <div>
        <!-- 密码输入框 -->
        <Field
          v-model="pwd"
          type="digit"
          label="密码"
          placeholder="请输入6位数密码"
        />
      </div>
    </Dialog>
    <Dialog
      v-model="show6"
      title="是否停用该柜子？"
      showCancelButton
      :showConfirmButton="false"
      class="modal"
    >
      <Button type="primary" size="large" style="margin-bottom: 10px" @click="changeStatus(4)">是</Button>
      <Button type="primary" size="large" style="margin: 10px 0" @click="show6=false">否</Button>
    </Dialog>
    <Dialog
      v-model="show7"
      title="是否设置柜子损坏？"
      showCancelButton
      :showConfirmButton="false"
      class="modal"
    >
      <Button type="primary" size="large" style="margin-bottom: 10px" @click="changeStatus(5)">是</Button>
      <Button type="primary" size="large" style="margin: 10px 0" @click="show7=false">否</Button>
    </Dialog>
    <Dialog
      v-model="show8"
      title="是否设置柜子正常？"
      showCancelButton
      :showConfirmButton="false"
      class="modal"
    >
      <Button type="primary" size="large" style="margin-bottom: 10px" @click="changeStatus(1)">是</Button>
      <Button type="primary" size="large" style="margin: 10px 0" @click="show8=false">否</Button>
    </Dialog>
  </div>
</template>

<script>
import { PullRefresh, Button, Dialog, Overlay, Loading, Field } from 'vant'
import axios from 'axios'
export default {
  name: 'doorDetail',
  data() {
    return {
      name: '',
      show: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      loading: false,
      isAble: false,
      pwd: '',
      showPwdKeyboard: false,
      list: [],
      current: {},
      colorMap: {
        1: '#07c160',
        2: '#e59760',
        3: '#969799',
        4: '#e7263c',
        5: '#000',
        6: '#5f5eb7'
      },
      orderData: {},
      currentNo: ''
    }
  },
  created() {
    const name = window.decodeURIComponent(window.location.href.split('=')[1])
    this.name = name
    this.getData()
  },
  components: {
    PullRefresh, Button, Dialog: Dialog.Component, Overlay, Loading, Field
  },
  methods: {
    getData(){
      this.show = true
      axios.post('/sso/locker/pageList', {
        deviceId: this.$route.params.id
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.list = res.data.result || []
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    onRefresh(){
      this.getData()
    },
    openBox(type){
      this.show = true
      axios.post('/sso/locker/adminOpeOneBox', {
        deviceId: this.current.deviceId,
        boxNo: this.current.boxNo,
        optType: type,
        isOpen: 1
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.show = false
          this.show3 = false
          this.current = {}
          const status = res.data.result
          if (status === 1) {
            this.$toast.success('开门成功')
          } else{
            this.$toast.fail('开门失败')
          }
          this.getData()
        } else {
          this.show = false
          this.show3 = false
          this.loading = false
        }
      })
    },
    openAllBox(){
      this.show4 = false
      this.show = true
      axios.post('/sso/locker/setLockerOpenAllByAdmin', {
        deviceId: this.$route.params.id,
        optType: 1,
        isOpen: 1
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const status = res.data.result
          if (status === 1) {
            this.$toast.success('开门成功')
          } else{
            this.$toast.fail('开门失败')
          }
          this.getData()
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    showModal1(v){
      if (v.status === 6) {
        this.$toast.fail('该柜子已封存！')
        return
      }
      this.show = true
      this.current = v
      axios.post('/sso/order/selectOneOrderByLockerIdAndBoxNo', {
        deviceId: this.$route.params.id,
        boxNo: v.boxNo,
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const data = res.data.result
          this.show1 = true
          this.show = false
          this.orderData = data
          this.currentNo = v.alias + '-' + ('0' + v.boxNo).substring(('0' + v.boxNo).length - 2)
        }
      })
    },
    showModal2(){
      this.show1 = false
      this.show2 = true
    },
    showModal3(){
      this.show1 = false
      this.show3 = true
    },
    showModal4(){
      this.show6 = true
    },
    showModal5(){
      this.show7 = true
    },
    showModal6(){
      this.show8 = true
    },
    changeStatus(status){
      this.show2 = false
      this.show = true
      axios.post('/sso/locker/adminLockBox', {
        deviceId: this.$route.params.id,
        boxNo: this.current.boxNo,
        status
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const status = res.data.result
          if (status === 1) {
            this.$toast.success('修改成功')
          } else{
            this.$toast.fail('修改失败')
          }
          this.getData()
          this.current = {}
          this.show = false
          this.loading = false
        } else {
          this.current = {}
          this.show = false
          this.loading = false
        }
      })
    },
    onPwdInput(key) {
      this.pwd = (this.pwd + key).slice(0, 6);
    },
    onPwdDelete() {
      this.pwd = this.pwd.slice(0, this.pwd.length - 1);
    },
    onpaymentyes(){
      if (!this.pwd || this.pwd.length !== 6) {
        this.$toast.fail('请输入正确位数的密码后再试')
        return
      }
      axios.post('/sso/locker/checkOpenLockPwd', {
        password: this.pwd
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const status = res.data.result
          if (status === 1) {
            // this.$toast.success('密码验证成功')
            this.show4 = true
            this.pwd = ''
          } else{
            this.$toast.fail('密码错误')
            this.pwd = ''
          }
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: auto;
    /* background: #fff; */
    font-size: 14px;
  }
  .box{
    min-height: calc(100vh - 0.8rem)
  }
  .topWrap{
    text-align: center;
    padding: 10px;
    background: #fff;
    color: red;
    font-weight: bolder;
    margin-bottom: 10px;
    font-size: 25px;
  }
  .nameWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
  }
  .refresh{
    height: auto;
  }
  .midWrap{
    background: #1bb13b;
    color: #fff;
    margin-top: 24px;
    padding: 20px 25px;
    border-radius: 10px;
  }
  .titleWrap{
    text-align: center;
    font-size: 15px;
    font-weight: bolder;
  }
  .titleWrap2{
    font-size: 13px;
    font-weight: bolder;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .countWrap{
    margin-top: 15px;
  }
  .botWrap{
    background: #fff;
    border-radius: 10px;
    margin-top: 15px;
  }
  .cardWrap{
    overflow: hidden;
    padding: 10px 0;
  }
  .iconWrap{
    margin-bottom: 5px;
  }
  .iconWrap > i{
    font-size: 20px;
    color: #fff;
  }
  .xz{
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #07c160;
    border-radius: 4px;
    margin: 0 5px;
    position: relative;
    /* top: 2px; */
  }
  .syz{
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #e59760;
    border-radius: 4px;
    margin: 0 5px;
    position: relative;
    /* top: 2px; */
  }
  .yc{
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #969799;
    border-radius: 4px;
    margin: 0 5px;
    position: relative;
    /* top: 2px; */
  }
  .ty{
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #e7263c;
    border-radius: 4px;
    margin: 0 5px;
    position: relative;
    /* top: 2px; */
  }
  .sh{
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #000;
    border-radius: 4px;
    margin: 0 5px;
    position: relative;
    /* top: 2px; */
  }
  .fc{
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #5f5eb7;
    border-radius: 4px;
    margin: 0 5px;
    position: relative;
    /* top: 2px; */
  }
  .itemWrap{
    border-radius: 8px;
    float: left;
    width: calc(25% - 10px);
    border: 1px solid #e8e8e8;
    height: 14vw;
    box-sizing: border-box;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .modal{
    padding: 10px;
  }
  .modal2{
    width: 300px;
  }
  .content{
    width: 200px;
    height: 100px;
  }
  .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .popup{
    width: 350px;
    height: 180px;
    padding-top: 16px;
    text-align: center;
    border-radius: 7px;
}
.van-popup--center{
    border-radius: 7px;
    overflow: hidden;
}
.popup_title{
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:16px;
    font-family:Source Han Sans CN;
    font-weight:500;
    color:rgba(51,51,51,1);
}
.popup_title_{
    text-align: center;
}
.popup_yes{
    margin: 0 auto;
    margin-top: 50px;
}
.popup_{
    position: fixed;
    bottom: 0;
    z-index: 99999;
}
.currentNo{
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin: 5px 0;
}
.warn{
  color: red;
  font-size: 14px;
  margin: 5px 0;
}
.contentWrap{
  background: #f7f4f4;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.contentWrap>div{
  margin: 15px 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.btnWrap>div{
  display: inline-block;
  width: 33.33%;
  padding: 5px;
  width: calc(33.33% - 10px);
}
.num3{
  color: #07c160;
  font-weight: bold;
}
</style>
