<template>
  <div class="wrap">
    <div class="box">
      <Form @submit="onSubmit">
        <CellGroup inset style="border-radius: 10px;overflow: hidden;">
          <Field
            v-model="username"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <Field
            v-model="password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
        </CellGroup>
        <div style="marginTop: 36px;">
          <Button block type="primary" native-type="submit">
            登录
          </Button>
        </div>
      </Form>
    </div>
    <!-- <a class="bah" href="https://beian.miit.gov.cn/" target="_blank">渝ICP备2022008234号-2</a> -->
  </div>
</template>

<script>
import { Form, Field, CellGroup, Button } from 'vant'
import axios from 'axios'
export default {
  name: 'loginPage',
  data() {
    return {
      username: '',
      password: ''
    }
  },
  created() {
    const time = localStorage.getItem('time')
    if (Date.now() - time > (3 * 86400 * 1000)) {
      localStorage.clear()
    }
    const username = localStorage.getItem('username') 
    const password = localStorage.getItem('password')
    if (username && password) {
      this.username = username
      this.password = password
    }
  },
  components: {
    Form, Field, CellGroup, Button
  },
  methods: {
    onSubmit(){
      this.login()
    },
    setData(a, b){
      localStorage.setItem('username', a) 
      localStorage.setItem('password', b)
      localStorage.setItem('time', Date.now())
    },
    login(){
      axios.post('/sso/jwt/login', {
        userName: this.username,
        password: this.password
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.$store.dispatch('getUserInfo', res.data.result)
          sessionStorage.setItem('token', res.data.result.token)
          sessionStorage.setItem('menuList', JSON.stringify(res.data.result.menuList))
          sessionStorage.setItem('userVo', JSON.stringify(res.data.result.userVO))
          this.setData(this.username, this.password)
          this.$router.push('/userPage')
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background: url('@/assets/bg.jpg') no-repeat center center;
    background-size: 100% 100%;
    font-size: 14px;
  }
  .box{
    padding: 30px;
    margin-top: 30vh;
  }
  .mmWrap{
    display: flex;
    justify-content: end;
    position: relative;
    top: 15px;
  }
  .bah{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
  }
</style>
