<template>
  <div class="wrap">
    <Field
      v-model="name"
      center
      label="场地"
      placeholder="请输入场地名称"
      class="inputWrap"
    >
      <template #button>
        <Button size="small" type="primary" @click="onRefresh">搜索</Button>
      </template>
    </Field>
    <div class="wrap2" id="scrollBox">
      <PullRefresh
        success-text="刷新成功"
        v-model="loading"
        @refresh="onRefresh"
        class="refresh"
      >
        <div v-if="list.length" class="box">
          <div v-for="(item, index) in list" :key="index" class="orderWrap">
            <div class="titleWrap">
              <span>{{ item.areaName || '--' }}</span>
              <Button size="small" type="primary" class="topBtn" @click="show2=true;areaCode=item.areaCode">新增</Button>
            </div>
            <div v-for="(ele, j) in item.lockerList" :key="j" class="conWrap">
              <span class="text1">{{ele.lockerName}}&nbsp;-&nbsp;<span :style="{color: colorMap[ele.lockerStatus]}">{{ statusMap[ele.lockerStatus] }}</span></span>
              <Button type="primary" class="btn" size="small" @click="goDetail(ele, item)">查看详情</Button>
            </div>
            <div>
              <Button size="large" type="info" class="topBtn2" @click="changeStatus(item.areaCode)">手动更新柜门状态</Button>
            </div>
          </div>
        </div>
        <Empty description="暂时没有数据" v-else />
      </PullRefresh>
    </div>
    <Dialog
      :value="show2"
      :overlay="true"
      title="新增区域"
      showCancelButton
      showConfirmButton
      @confirm="addArea"
      @cancel="show2=false"
      class="modal"
    >
      <Field
        v-model="lockerName"
        required
        label="设备名称："
        placeholder="请输入设备名称"
      />
      <Field
        v-model="lockerId"
        required
        label="设备编号："
        placeholder="请输入设备编号"
      />
      <div class="radioWrap">
        <span><span style="color: red">*</span>设备微信类型：</span>
        <RadioGroup v-model="lockerServerType" direction="horizontal">
          <Radio name="mp">公众号</Radio>
          <Radio name="miniapp">小程序</Radio>
          <!-- <Radio name="miniapp">博联小程序</Radio>
          <Radio name="xq_miniapp">小悫小程序</Radio>
          <Radio name="xl_miniapp">小联小程序</Radio> -->
        </RadioGroup>
      </div>
      <div class="radioWrap">
        <span><span style="color: red">*</span>模式类型：</span>
        <RadioGroup v-model="lockerRefundType" direction="horizontal">
          <Radio name="1">及时</Radio>
          <Radio name="2">次日</Radio>
        </RadioGroup>
      </div>
      <Field
        v-model="maxRefundRate"
        type="number"
        label="比例值："
        placeholder="请输入比例值"
      />
      <Field
        v-model="lockerTotal"
        type="digit"
        label="总柜门数："
        placeholder="柜门数不能大于100"
      />
      <Field
        v-model="lockerLockNum"
        required
        type="digit"
        label="锁定柜门号："
        placeholder="请输入锁定柜门号"
      />
      <Field
        v-model="depositAmount"
        type="number"
        label="押金："
        placeholder="请输入押金"
      />
      <Field
        v-model="lockerAmount"
        type="number"
        label="收费："
        placeholder="请输入收费金额"
      />
    </Dialog>
    <Overlay :show="show2" class="overlay" />
    <Overlay :show="show" class="overlay">
      <Loading type="spinner" />
    </Overlay>
  </div>
</template>

<script>
import { Empty, PullRefresh, Overlay, Loading, Field, Button, Dialog, RadioGroup, Radio } from 'vant'
import axios from 'axios'
const statusMap = {
  1: '在线',
  2: '不在线'
}
const colorMap = {
  1: '#1c19fa',
  2: '#302727'
}
export default {
  name: 'boxManage',
  data() {
    return {
      name: '',
      areaCode: '',
      show: false,
      show2: false,
      refreshing: false,
      loading: false,
      finished: true,
      list: [],
      statusMap,
      colorMap,
      current: {},
      lockerName: '',
      lockerLockNum: '27',
      lockerId: '',
      lockerAmount: '',
      depositAmount: '10',
      lockerTotal: '64',
      lockerServerType: 'mp',
      lockerRefundType: '2',
      maxRefundRate: '',
      scrollY: 0
    }
  },
  created() {
    this.getData()
  },
  components: {
    Empty, PullRefresh, Overlay, Loading, Field, Button, Dialog: Dialog.Component, RadioGroup, Radio
  },
  activated() {
    document.querySelector('#scrollBox').scrollTop = this.scrollY
  },
  deactivated() {
  },
  methods: {
    getData(code){
      this.show = true
      axios.post('/sso/static/getPageAreaList').then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          let list = res.data.result || []
          this.list = list.filter(item => item.areaName.indexOf(this.name) > -1)
          this.show = false
          this.loading = false
          if (code) {
            this.scrollY = 0
            document.querySelector('#scrollBox').scrollTop = 0
          }
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    changeStatus(areacode){
      this.show = true
      this.loading = true
      axios.post('/sso/static/adminByUpdateLockerStatus', { lockerAreaCode: areacode }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.$toast("更新成功！")
          this.getData()
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    onRefresh(){
      this.getData(1)
    },
    addArea(){
      if (!this.lockerName || !this.lockerId || !this.lockerLockNum) {
        this.$toast.fail('设备名称、设备编号、锁定柜门号必须填写！')
        return
      }
      this.show = true
      axios.post('/sso/static/setCreateDeviceByAdmin', {
        areaCode: this.areaCode,
        lockerName: this.lockerName,
        deviceId: this.lockerId,
        lockerAmount: this.lockerAmount,
        maxRefundRate: this.maxRefundRate,
        depositAmount: this.depositAmount,
        lockerTotal: this.lockerTotal,
        lockerLockNum: this.lockerLockNum,
        lockerServerType: this.lockerServerType,
        lockerRefundType: this.lockerRefundType
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const status = res.data.result
          if (status === 1) {
            this.$toast.success('新增成功')
            this.show2 = false
            this.areaCode = ''
            this.lockerName = ''
            this.lockerId = ''
            this.lockerAmount = ''
            this.maxRefundRate = ''
            this.depositAmount = '10'
            this.lockerTotal = '64'
            this.lockerLockNum = '27'
            this.lockerServerType = 'mp'
            this.lockerRefundType = '2'
            this.getData()
          } else{
            this.$toast.fail('新增失败')
            this.show2 = false
          }
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    goDetail(data, record){
      this.scrollY = document.querySelector('#scrollBox').scrollTop
      const name = window.encodeURIComponent(record.areaName)
      this.$router.push(`/placeDetail/${data.lockerId}?name=` + name)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: hidden;
    /* background: #fff; */
    font-size: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .wrap2{
    overflow: auto;
    flex-grow: 1;
    margin-top: 45px;
  }
  .refresh{
    height: auto;
  }
  .listWrap{
    height: 100%;
    overflow: auto;
  }
  .box{
    min-height: calc(100vh - 2.13333rem);
  }
  .inputWrap{
    position: absolute;
    height: 35px;
  }
  .orderWrap{
    background: linear-gradient(135deg,#1ae179 0%,#108c97 100%);
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;
  }
  .titleWrap{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bolder;
    border-bottom: 1px solid #f2f3f5;
    color: #fff;
    position: relative;
  }
  .conWrap{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
    position: relative;
  }
  .conWrap:last-child{
    border-bottom: 0;
  }
  .text1{
    color: #fff;
    display: inline-block;
    line-height: 32px;
  }
  .status{
    color: #1c19fa;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
  .btn{
    background-color: #0744c1;
    border-radius: 5px;
    border: 0;
    right: 20px;
    position: absolute;
    top: 20px;
  }
  .topBtn{
    background-color: #0744c1;
    border-radius: 5px;
    border: 0;
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .topBtn2{
    background-color: forestgreen;
    height: 0.8rem;
  }
  .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .radioWrap{
    display: flex;
    font-size: 14px;
    height: 44px;
    align-items: center;
    border-bottom: 0.02667px solid #ebedf0;
    margin: 0 10px;
  }
</style>
